import {
  Box,
  Input,
  PrimaryButton,
  SegmentedControl,
  Stack,
  system,
  SystemProps,
  TertiaryButton,
  Text,
} from "flicket-ui";
import { ChangeEvent, useState } from "react";
import styled from "styled-components";
import { Select } from "~components";
import { useReleases } from "~graphql";
import { showErrorToast } from "~lib";
import { headerTextMap, modalOptions, ModalType } from "./InsertModal.types";
import {
  ButtonElement,
  LinkElement,
  SuggestedLinkType,
} from "./interface/slate.interface";
import { atom, useAtom } from "jotai";
import CustomModal from "../CustomModal";
import { ReactEditor, useSlate } from "slate-react";
import { wrapLink } from "./Link";
import { addProtocolToURL } from "~lib/helpers/addProtocolToURL";
import { Editor, Range, Transforms } from "slate";
import { isLinkElement } from "./interface/slate.predicates";
import {
  isSpecificEventSource,
  isSpecificMembershipSource,
} from "~features/reports/reporting/components/SourceSwitcher/interfaces";
import SourceSwitcher from "~features/reports/reporting/components/SourceSwitcher/SourceSwitcher";

const StyledLabel = styled.label<SystemProps>`
  display: block;
  color: ${(p) => p.theme.colors.N600};
  font-size: ${(p) => p.theme.fontSizes[3]};
  font-weight: ${(p) => p.theme.fontWeights.extraBold};
  letter-spacing: -0.165px;

  ${system}
`;

export const defaultLinkAndButtonAtomState: LinkAndButtonAtomState = {
  isOpen: false,
  element: null,
  selectEvents: false,
  allowTogglingLinkOrButton: false,
};

interface LinkAndButtonAtomState {
  isOpen: boolean;
  element?: ButtonElement | LinkElement;
  allowTogglingLinkOrButton?: boolean;
  selectEvents?: boolean;

  defaultValues?: {
    displayText?: string;
    url?: string;
    suggestedLink?: SuggestedLinkType;
  };
}
export const linkAndButtonModalAtom = atom<LinkAndButtonAtomState>(
  defaultLinkAndButtonAtomState
);

export const getHeaderText = ({
  suggestedLink,
  type,
  isEditing,
}: {
  suggestedLink?: SuggestedLinkType | undefined;
  type: ModalType;
  isEditing: boolean;
}) => {
  const text = suggestedLink ? headerTextMap[suggestedLink] : undefined;
  if (!suggestedLink) {
    let prefix = "Insert",
      suffix = "link";
    if (isEditing) {
      prefix = "Edit";
    }
    if (type === "button") {
      suffix = "button";
    }
    return `${prefix} ${suffix}`;
  }

  return isEditing ? `Edit ${text ? text.toLowerCase() : ""}` : text;
};

interface InsertLinkAndButtonFormProps {
  isSms?: boolean;
  close: () => void;
}

function InsertLinkAndButtonForm(props: InsertLinkAndButtonFormProps) {
  const { isSms, close } = props;
  const [config] = useAtom(linkAndButtonModalAtom);
  const { allowTogglingLinkOrButton, selectEvents } = config;

  const isEditing = !!config.element;
  const [element, setElement] = useState<LinkElement | ButtonElement>(
    isEditing
      ? config.element
      : {
          type: "link",
          suggestedLink: config.defaultValues?.suggestedLink,
          children: [{ text: "" }],
          content: config.defaultValues?.displayText,
          url: config.defaultValues?.url,
          releaseId: config.element?.releaseId,
        }
  );

  const registrationEventsOnly = element.suggestedLink === "event-registration";

  const editor = useSlate();

  const headerText = getHeaderText({
    type: element.type,
    isEditing,
    suggestedLink: element.suggestedLink,
  });

  const shouldLoadEvents =
    element.suggestedLink &&
    (selectEvents || element.eventId || element.membershipId);

  const enableReleaseList = [
    SuggestedLinkType.EVENT_OR_MEMBERSHIP_TICKETS,
    SuggestedLinkType.EVENT_TICKETS,
  ];

  const eventId =
    !enableReleaseList.includes(element.suggestedLink) || !element.eventId
      ? null
      : element.eventId;

  const { data: releaseData } = useReleases(eventId);

  return (
    <>
      <CustomModal.Header>{headerText}</CustomModal.Header>

      <CustomModal.Content overflowY={"visible"}>
        <Stack gap={2} direction={"vertical"}>
          {allowTogglingLinkOrButton && !isEditing && (
            <Box>
              <StyledLabel pb={1}>Display as</StyledLabel>
              <SegmentedControl
                value={element.type}
                onChange={(type: ModalType) => setElement({ ...element, type })}
                data={modalOptions.map(({ label, type }) => ({
                  label,
                  value: type,
                }))}
              />
            </Box>
          )}

          {shouldLoadEvents && (
            <Box>
              <StyledLabel pb={1}>Event</StyledLabel>
              <SourceSwitcher
                initialValue={
                  element.eventId
                    ? {
                        eventId: element.eventId,
                      }
                    : element.membershipId
                    ? { membershipId: element.membershipId }
                    : undefined
                }
                small
                displayMembershipsTab={!!isSms}
                displayPackagesTab={false}
                displaySeasonsTab={false}
                displayOverallTab={false}
                hideAllEventsOption={true}
                searchEventFilter={{
                  activeEventsOnly: true,
                  hasRegistrationEnabled: registrationEventsOnly ? true : null,
                }}
                placeholder={
                  registrationEventsOnly ? "Choose an event" : undefined
                }
                onChange={(source, searchData) => {
                  if (isSpecificEventSource(source)) {
                    const foundValue = searchData.searchableEvents?.find(
                      (e) => e.id === source.eventId
                    );

                    setElement({
                      ...element,
                      eventId: source.eventId,
                      membershipId: undefined,
                      content: foundValue.title,
                      url: undefined,
                    });
                  }

                  if (isSpecificMembershipSource(source)) {
                    const foundValue = searchData.searchableMemberships?.find(
                      (e) => e.id === source.membershipId
                    );

                    setElement({
                      ...element,
                      eventId: undefined,
                      membershipId: source.membershipId,
                      content: foundValue.name,
                      url: undefined,
                    });
                  }
                }}
              />
              {isSms && (
                <Text mt={4} mb={4} fontSize={"14px" as any}>
                  Link will be automatically shortened to 22 characters.
                </Text>
              )}

              {registrationEventsOnly && (
                <Text fontSize={2} color="N600" mt={1}>
                  Only showing events with registration enabled.
                </Text>
              )}
            </Box>
          )}

          {releaseData?.length ? (
            <Select
              key={`${element.eventId}`}
              options={releaseData?.map((release) => ({
                label: release.name,
                value: release.id,
              }))}
              defaultValue={element.releaseId}
              label={"Release"}
              onChange={(option: string) => {
                setElement({
                  ...element,
                  releaseId: option,
                });
              }}
            />
          ) : null}

          {!isSms && (
            <Input
              label="Display text"
              autoFocus
              value={element.content}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setElement({ ...element, content: e.target.value })
              }
            />
          )}

          {!element.suggestedLink && (
            <Input
              label={"URL"}
              value={element.url}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const url = e.target.value;
                setElement({
                  ...element,
                  url,
                  ...(isSms ? { content: url } : {}),
                });
              }}
            />
          )}
        </Stack>
      </CustomModal.Content>

      <CustomModal.Footer>
        <Stack gap={1}>
          <TertiaryButton onClick={close}>Cancel</TertiaryButton>
          <PrimaryButton
            onClick={() => {
              if (!element.content) {
                showErrorToast("Please fill in display text.");
              } else if (!element.url && !element.suggestedLink) {
                showErrorToast("Please fill in URL.");
              } else if (
                shouldLoadEvents &&
                !element.eventId &&
                !element.membershipId
              ) {
                showErrorToast("Please select an event or membership.");
              } else {
                if (isEditing && element.type === "link") {
                  editLink(editor, element);
                } else if (isEditing && element.type === "button") {
                  editButton(editor, element);
                } else if (!isEditing && element.type === "link") {
                  insertLink(editor, element);
                } else if (!isEditing && element.type === "button") {
                  insertButton(editor, element);
                }

                close();
              }
            }}
          >
            Apply
          </PrimaryButton>
        </Stack>
      </CustomModal.Footer>
    </>
  );
}

export function InsertLinkAndButtonModal({ isSms }: { isSms?: boolean }) {
  const [state, setState] = useAtom(linkAndButtonModalAtom);

  const close = () => {
    setState({ ...state, isOpen: false });
  };

  return (
    <CustomModal
      isOpen={state.isOpen}
      close={close}
      overflowY={"visible"}
      alignModalTop
    >
      {state.isOpen && <InsertLinkAndButtonForm isSms={isSms} close={close} />}
    </CustomModal>
  );
}

const editLink = (editor: Editor, element: LinkElement) => {
  const blurSelection = editor.blurSelection;
  if (!blurSelection) {
    console.error("Cannot edit link without selection.");
    return;
  }

  const newElement = { ...element };

  if (newElement.url) {
    newElement.url = addProtocolToURL(newElement.url);
  }

  Transforms.setNodes(editor, newElement, {
    at: blurSelection,
    match: (n) => isLinkElement(n),
    mode: "lowest",
  });

  // refocus
  // https://github.com/ianstormtaylor/slate/issues/3412#issuecomment-663906003
  editor.selection = blurSelection;
  ReactEditor.focus(editor);
};

const editButton = (editor: Editor, element: ButtonElement) => {
  const blurSelection = editor.blurSelection;
  if (!blurSelection) {
    console.error("Cannot edit button without selection.");
    return;
  }

  const newElement = { ...element };

  if (newElement.url) {
    newElement.url = addProtocolToURL(newElement.url);
  }

  Transforms.setNodes(editor, element, {
    at: blurSelection,
  });

  // refocus
  // https://github.com/ianstormtaylor/slate/issues/3412#issuecomment-663906003
  editor.selection = blurSelection;
  ReactEditor.focus(editor);
};

export const insertLink = (editor: Editor, element: LinkElement) => {
  let blurSelection = editor.blurSelection;
  if (!blurSelection) {
    blurSelection = {
      anchor: {
        offset: 0,
        path: [0, 0],
      },
      focus: {
        offset: 0,
        path: [0, 0],
      },
    };
  }
  const {
    content,
    suggestedLink,
    url,
    eventId,
    releaseId,
    membershipId,
  } = element;

  if (blurSelection) {
    wrapLink(editor, {
      url,
      at: blurSelection,
      content,
      suggestedLink,
      eventId,
      releaseId,
      membershipId,
    });
    // use the start of the link as the new selection
    // use the end of the link as offset will cause an error (not sure why)
    const minOffset = Math.min(
      blurSelection.anchor.offset,
      blurSelection.focus.offset
    );
    const newRange: Range = {
      anchor: { offset: minOffset, path: blurSelection.anchor.path },
      focus: { offset: minOffset, path: blurSelection.anchor.path },
    };
    editor.selection = newRange;
    ReactEditor.focus(editor);
  }
};

export const insertButton = (editor: Editor, element: ButtonElement) => {
  let blurSelection = editor.blurSelection;
  if (!blurSelection) {
    blurSelection = {
      anchor: {
        offset: 0,
        path: [0, 0],
      },
      focus: {
        offset: 0,
        path: [0, 0],
      },
    };
  }

  const newElement = { ...element };

  if (newElement.url) {
    newElement.url = addProtocolToURL(newElement.url);
  }

  Transforms.insertNodes(editor, element, {
    at: blurSelection,
  });
  // refocus
  // https://github.com/ianstormtaylor/slate/issues/3412#issuecomment-663906003
  editor.selection = blurSelection;
  ReactEditor.focus(editor);
};
